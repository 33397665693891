import mdiVue from "mdi-vue/v3";
// import * as mdijs from "@mdi/js";
import {
  mdiViewDashboardOutline,
  mdiMessageFastOutline,
  mdiLanConnect,
  mdiBullhornOutline,
  mdiHomeCityOutline,
  mdiKnifeMilitary,
  mdiSkullOutline,
  mdiCashPlus,
  mdiCashMinus,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiFileUploadOutline,
  mdiMenu,
  mdiClose,
  mdiExitToApp,
  mdiAccountCircleOutline,
  mdiLogoutVariant,
  mdiArrowTopLeft,
  mdiLinkBoxOutline,
  mdiOpenInNew,
  mdiAccountOutline,
  mdiFormTextboxPassword,
  mdiGithub,
  mdiClockOutline,
  mdiFaceWomanShimmerOutline,
  mdiFolderArrowLeftRightOutline,
  mdiFolderAccountOutline,
  mdiFolderCogOutline,
  mdiFormatListBulletedSquare,
  mdiFileImageOutline,
  mdiForumOutline,
  mdiAccountCashOutline,
  mdiCash100,
  mdiOfficeBuildingOutline,
  mdiTransitConnectionVariant,
  mdiCogOutline,
  mdiEmoticonLolOutline,
  mdiApplicationBracketsOutline,
  mdiNewspaperVariantOutline,
  mdiMessageTextOutline,
  mdiMessageProcessingOutline,
  mdiLoading,
  mdiMessageFlashOutline,
  mdiFilterOutline,
  mdiAccessPointNetwork,
  mdiChartLine,
  mdiServerNetwork,
  mdiChartScatterPlot,
} from "@mdi/js";

export default mdiVue;
export const config = {
  icons: {
    mdiViewDashboardOutline,
    mdiMessageFastOutline,
    mdiLanConnect,
    mdiBullhornOutline,
    mdiHomeCityOutline,
    mdiKnifeMilitary,
    mdiSkullOutline,
    mdiCashPlus,
    mdiCashMinus,
    mdiFullscreen,
    mdiFullscreenExit,
    mdiFileUploadOutline,
    mdiMenu,
    mdiClose,
    mdiExitToApp,
    mdiAccountCircleOutline,
    mdiLogoutVariant,
    mdiArrowTopLeft,
    mdiLinkBoxOutline,
    mdiOpenInNew,
    mdiAccountOutline,
    mdiFormTextboxPassword,
    mdiGithub,
    mdiClockOutline,
    mdiFaceWomanShimmerOutline,
    mdiFolderArrowLeftRightOutline,
    mdiFolderAccountOutline,
    mdiFolderCogOutline,
    mdiFormatListBulletedSquare,
    mdiFileImageOutline,
    mdiForumOutline,
    mdiAccountCashOutline,
    mdiCash100,
    mdiOfficeBuildingOutline,
    mdiTransitConnectionVariant,
    mdiCogOutline,
    mdiEmoticonLolOutline,
    mdiApplicationBracketsOutline,
    mdiNewspaperVariantOutline,
    mdiMessageTextOutline,
    mdiMessageProcessingOutline,
    mdiLoading,
    mdiMessageFlashOutline,
    mdiFilterOutline,
    mdiAccessPointNetwork,
    mdiChartLine,
    mdiServerNetwork,
    mdiChartScatterPlot,
  },
};
