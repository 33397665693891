import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./plugins/router";
import store from "./plugins/store";
import sentry from "./plugins/sentry";

import ElementPlus, { config as ElementPlusConfig } from "@/plugins/element-plus";

import MDIcon, { config as MDIconConfig } from "@/plugins/md-icon";

import "reset.css";
import "animate.css";
import "@/styles/global.scss";

import "./permission";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, ElementPlusConfig);
app.use(MDIcon, MDIconConfig);

sentry({ router, app });

app.mount("#app");

export default app;
