import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "MainLayout",
    component: () => import("@/layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        name: "AppMain",
        redirect: "/dashboard",
        component: () => import("@/layouts/AppMain.vue"),
        meta: { transition: "fadeIn" },
        children: [],
      },
      {
        path: "/loading",
        name: "AppLoading",
        component: () => import("@/layouts/AppLoading.vue"),
        meta: { transition: "fadeOut" },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/AuthLogin.vue"),
    meta: { title: "登录", bypassLogin: true },
  },
  {
    path: "/(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
