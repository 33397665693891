import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
// import "element-plus/dist/index.css";
// import "./style.scss";

export default ElementPlus;

export const config = {
  locale: zhCn,
  //   size: "small",
};
