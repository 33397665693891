import NStorage from "storage4ns";

export const namespace = "utoverse-platform";

const nstore = new NStorage(namespace);

export enum StorageKey {
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "refresh_token",
  USER_INFO = "user_info",
  PERMISSION = "permission",
  MENU = "menu_perms",
  SIDEBAR_STATUS = "sidebar_status",
}

export default nstore;
