const constantsRoutes: MenuRecord[] = [
  {
    component: "views/dashboard/DashboardIndex.vue",
    createTime: "1640966400000",
    fullPath: "/dashboard",
    icon: "view-dashboard-outline",
    id: 6,
    keepAlive: true,
    name: "Dashboard",
    parent: 0,
    path: "dashboard",
    title: "仪表盘",
    redirect: "",
    children: [],
    orderIndex: 0,
  },
];

export default constantsRoutes;
