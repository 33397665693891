import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { Router } from "vue-router";
import { Vue } from "@sentry/vue/types/types";
import { getProjectVersion } from "@/utils/util";

interface SentryInitConfig {
  app: Vue;
  router: Router;
}

function init(option: SentryInitConfig) {
  Sentry.init({
    app: option.app,
    environment: process.env.NODE_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: getProjectVersion(true),
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(option.router),
        tracingOrigins: ["localhost", /^\//, "*"],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export default function (option: SentryInitConfig) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const appEnv = (process.env.ENVIRONMENTS as any)["APP_ENV"];
  if (appEnv === "prod") {
    init(option);
  }
}
