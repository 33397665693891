import dayjs from "dayjs";
import axios from "axios";

export function timestamp2Date(timestamp: number | string): string {
  const stamp = typeof timestamp === "string" ? parseInt(timestamp) : timestamp;
  return dayjs(stamp).format("YYYY-MM-DD HH:mm:ss");
}

export function dateTimeToReadable(time: number | string): string {
  return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
}

export function getKodoThumbnailPath(imgPath: string): string {
  if (!imgPath || !imgPath.startsWith("http")) return imgPath;
  return `${imgPath}?imageMogr2/auto-orient/thumbnail/!80p/format/webp/quality/70`;
}

export function flatTree<T>(_treeList: T[], _childKey: keyof T): T[] {
  const flatTreeList: T[] = [];
  function recursion(treeList: T[], childKey: keyof T): T[] {
    treeList.forEach((item: T) => {
      if (Array.isArray(item[childKey])) {
        recursion(item[childKey] as unknown as T[], childKey);
      }
      flatTreeList.push(item);
    });
    return flatTreeList;
  }
  return recursion(_treeList, _childKey);
}

export async function getFileType(url: string): Promise<string> {
  if (typeof url !== "string") return Promise.reject(new Error("请求的地址不是本地文件"));
  if (!url.startsWith("http")) return Promise.reject(new Error("请求的地址不是本地文件"));
  try {
    const response = await axios({ url, method: "HEAD" });
    return response.headers["content-type"] ?? "application/octet-stream";
  } catch (error) {
    return Promise.reject(error);
  }
}

export const getSummaryFromHtml = (html: string): string => {
  const dom = new DOMParser().parseFromString(html, "text/html");
  const text = dom.body.textContent || "";
  return text.slice(0, 100);
};

export function getProjectVersion(containEnv?: boolean): string;
export function getProjectVersion(): string {
  // eslint-disable-next-line prefer-rest-params
  const containEnv = arguments[0] ?? false;
  const versionNumber = process.env.VUE_APP_BUILD_VERSION_NUMBER || "dev";
  const commitHash = process.env.VUE_APP_BUILD_GIT_COMMIT || "hash";
  const rtnText = `1.${versionNumber}-${commitHash.substring(0, 7)}`;
  if (containEnv) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const appEnv = (process.env.ENVIRONMENTS as any)["APP_ENV"];
    return `${rtnText}_${appEnv}`;
  } else {
    return rtnText;
  }
}
