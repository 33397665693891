import router from "@/plugins/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import getPageTitle from "./utils/getPageTitle";
import storage, { StorageKey } from "./vendors/storage";
import constantsRoutes from "./plugins/router/constantsRoutes";

NProgress.configure({
  showSpinner: false,
  minimum: 0.3,
  speed: 1000,
  //   parent: ".router_view__wrapper",
});

export const mainRouteName = "AppMain";

router.beforeEach((to, from, next) => {
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta);

  // determine whether the user has logged in
  const hasToken = storage.get(StorageKey.ACCESS_TOKEN, "");

  if (hasToken) {
    if (to.name === "login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      NProgress.done();
    } else {
      if (!router.hasRoute(to.name || "")) {
        addRoutes(getRoutes(), mainRouteName).then(({ flat }) => {
          const toMenu = flat.find((item) => item.fullPath === to.fullPath);
          if (toMenu) next({ name: toMenu.name });
          else next({ name: mainRouteName });
        });
      } else {
        next();
      }
    }
  } else {
    // other pages that do not have permission to access are redirected to the login page.
    if (to.meta.bypassLogin !== true) {
      next(`/login?redirect=${to.path}`);
    } else next();
    NProgress.done();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export function getRoutes(): MenuRecord[] {
  const menuPerms = storage.get(StorageKey.MENU, []);
  return [...constantsRoutes, ...menuPerms];
}

/**
 * See: https://segmentfault.com/a/1190000040631051
 */
export function addRoutes(_menuList: MenuRecord[], _parentName = "") {
  const flatMenuList: MenuRecord[] = [];
  function recursion(menuList: MenuRecord[], parentName = "") {
    menuList.forEach((item) => {
      if (item.path && item.component) {
        flatMenuList.push(item);
        const componentString = item.component.replace(/^\/+/, "");
        const componentPath = componentString.replace(/\.\w+$/, "");

        const route = {
          path: item.path,
          redirect: item.redirect,
          name: item.name,
          component: () => import("@/" + componentPath + ".vue"),
          meta: {
            title: item.title,
            keepAlive: item.keepAlive,
            icon: item.icon,
            parentName,
          },
        };

        parentName ? router.addRoute(parentName, route) : router.addRoute(route);

        if (item.children && item.children.length) {
          recursion(item.children, item.name);
        }
      }
    });
  }
  recursion(_menuList, _parentName);
  return Promise.resolve({ tree: _menuList, flat: flatMenuList });
}

export const getRouterList = getRoutes;
